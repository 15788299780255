import React, { useCallback, useEffect, useRef } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import RemConverter from "@utils/remConverter";
import { LayoutWrapper } from "../../../../styles/CommonStyles";
import {
    VisionSectionContainer,
    Heading,
    VisionText,
    SelectedText,
} from "./VisionSection.style";

const convertRemToPx = RemConverter.convertRemToPixels;

const VisionSection = () => {
    const wrapperRef = useRef(null);
    const selectedTextWidth = useRef([]);
    const marginLeft = useRef(0);
    const { isDesktop, width } = useWindowDimensions();

    const calculateOffsetY = useCallback(
        pos => {
            const wrapperPos = wrapperRef.current.getBoundingClientRect();
            const elementPosY = pos.top - wrapperPos.top;

            return wrapperPos.height / 2 - elementPosY;
        },
        [wrapperRef.current]
    );

    const calculateOffsetX = useCallback(
        pos => {
            const wrapperPos = wrapperRef.current.getBoundingClientRect();
            const elementPosX = pos.left - wrapperPos.left;

            if (!selectedTextWidth.current.length) {
                selectedTextWidth.current.push(pos.width + convertRemToPx(0.7));

                return -elementPosX + marginLeft.current;
            }

            const totalTextWidth = selectedTextWidth.current.reduce(
                (acc, cur) => acc + cur,
                0
            );

            selectedTextWidth.current.push(pos.width + convertRemToPx(0.7));

            return totalTextWidth - elementPosX + marginLeft.current;
        },
        [wrapperRef.current, marginLeft.current, selectedTextWidth.current]
    );

    const setMarginLeft = useCallback(
        totalWidth => {
            const wrapperWidth =
                wrapperRef.current.getBoundingClientRect().width;
            const extraSpace = wrapperWidth - totalWidth;

            marginLeft.current = extraSpace / 2;
        },
        [wrapperRef.current]
    );

    useEffect(() => {
        if (!isDesktop || isDesktop === undefined) return;

        selectedTextWidth.current = [];

        gsap.registerPlugin(ScrollTrigger);

        const normalText = gsap.utils.toArray(".vision-section__text");
        const selectedText = gsap.utils.toArray(
            ".vision-section__selected-text"
        );

        const temp = selectedText[1];
        selectedText[1] = selectedText[2];
        selectedText[2] = temp;

        const selectedTextPositions = [];
        let totalWidth = 0;

        selectedText.forEach(el => {
            const pos = el.getBoundingClientRect();

            selectedTextPositions.push(pos);
            totalWidth += pos.width + convertRemToPx(0.7);
        });

        setMarginLeft(totalWidth);

        gsap.timeline({
            scrollTrigger: {
                id: "vision-trigger",
                trigger: ".vision-section__wrapper",
                scrub: 1,
                start: "+170 center",
                end: () => `+=100`,
            },
        })
            .to(normalText, {
                opacity: 0,
            })
            .to(
                selectedText,
                {
                    translateX: gsap.utils.wrap([
                        calculateOffsetX(selectedTextPositions[0]),
                        calculateOffsetX(selectedTextPositions[1]),
                        calculateOffsetX(selectedTextPositions[2]),
                        calculateOffsetX(selectedTextPositions[3]),
                        calculateOffsetX(selectedTextPositions[4]),
                        calculateOffsetX(selectedTextPositions[5]),
                    ]),
                    translateY: gsap.utils.wrap([
                        calculateOffsetY(selectedTextPositions[0]),
                        calculateOffsetY(selectedTextPositions[1]),
                        calculateOffsetY(selectedTextPositions[2]),
                        calculateOffsetY(selectedTextPositions[3]),
                        calculateOffsetY(selectedTextPositions[4]),
                        calculateOffsetY(selectedTextPositions[5]),
                    ]),
                },
                "<"
            );

        return () => {
            ScrollTrigger.getById("vision-trigger")?.kill();
        };
    }, [isDesktop, width]);

    return (
        <LayoutWrapper
            className="vision-section__wrapper"
            style={{
                maxWidth: "none",
            }}
        >
            <VisionSectionContainer>
                {isDesktop ? (
                    <>
                        <Heading>Our vision</Heading>
                        <VisionText ref={wrapperRef}>
                            <span className="vision-section__text">
                                Our main goal is
                            </span>
                            <SelectedText className="vision-section__selected-text">
                                to help
                            </SelectedText>
                            <span className="vision-section__text">
                                our customers turn their ideas into reality and
                                successfully
                            </span>
                            <SelectedText className="vision-section__selected-text">
                                enter
                            </SelectedText>
                            <span className="vision-section__text">
                                the market. We aim to work with
                            </span>
                            <SelectedText className="vision-section__selected-text">
                                global brands
                            </SelectedText>
                            <span className="vision-section__text">
                                that thrive to change
                            </span>
                            <SelectedText className="vision-section__selected-text">
                                the future
                            </SelectedText>
                            <span className="vision-section__text">
                                with the help
                            </span>
                            <SelectedText className="vision-section__selected-text">
                                of
                            </SelectedText>
                            <span className="vision-section__text">
                                cutting-edge
                            </span>
                            <SelectedText className="vision-section__selected-text">
                                technology
                            </SelectedText>
                            <span className="vision-section__text">.</span>
                        </VisionText>
                    </>
                ) : (
                    <>
                        <Heading>Our vision</Heading>
                        <VisionText>
                            To help global brands enter
                            <br /> the future of technology
                        </VisionText>
                    </>
                )}
            </VisionSectionContainer>
        </LayoutWrapper>
    );
};

export default VisionSection;
