import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const VisionSectionContainer = styled.div`
    padding-bottom: 2.5rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        padding: 2.5rem 0;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const VisionText = styled.h2`
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 200%;
    text-align: center;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        text-align: start;
        color: #c4b180;
    }
`;

export const SelectedText = styled.span`
    display: inline-block;
    color: #c4b180;
    margin: 0 0.7rem;
`;

export const Heading = styled.p`
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: 700;
    margin: 0 0 2.5rem 0;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 1.5rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
`;
